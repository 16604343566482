import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const TeachingPageTemplate = ({ title, content, contentComponent}) => {
  const PageContent = contentComponent || Content
  return (
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="section">
            <h1 className="title">{title}</h1>
            <hr />
            <PageContent className="content" content={content} />
          </div>
        </div>
      </div>
    </div>
  )
}

TeachingPageTemplate.propTypes = {}

const TeachingPage = ({ data }) => {
  const { markdownRemark } = data
  const title = markdownRemark.frontmatter.title

  return (
    <Layout>
      <TeachingPageTemplate
        contentComponent={HTMLContent}
        content={markdownRemark.html}
        title={title}
      />
    </Layout>
  )
}

TeachingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TeachingPage

export const teachingPageQuery = graphql`
  query TeachingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
      html
    }
  }
`
